<template>
  <!-- eslint-disable -->
  <v-main class="white">
    <router-view />
  </v-main>
</template>

<script>
/* eslint-disable */
export default {
  name: "DashboardCoreView",

  data() {
    return {
      credit_balance: localStorage.getItem("credit_balance"),
    };
  },
  components: {},
  components: {
    DashboardCoreFooter: () => import("./Footer"),
  },
};
</script>
